<template>
    <main class="flex-shrink-0">
      
      <!-- <h4 class="subtitle is-2">Shop</h4>
      <div class="table-container">
        <table class="table">
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <ul v-else-if="hasProfiles">
            <profile-abo-list-item
              v-for="sProfile in profilesList"
              :key="sProfile.id"
              :id="sProfile.id"
              :benutzername="sProfile.benutzername"
              :vorname="sProfile.vorname"
              :nachname="sProfile.nachname"
              :strasse="sProfile.strasse"
              :hausnummer="sProfile.hausnummer"
              :plz="sProfile.plz"
              :ort="sProfile.ort"
              :land="sProfile.land"
              :email="sProfile.email"
              :aktiv="sProfile.aktiv"
              :erstellt="sProfile.erstellt"
            ></profile-abo-list-item>
          </ul>
          <h3 v-else>Keine Daten gefunden.</h3>
        </table>
      </div> -->


      <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
        <div class="col-md-6 p-lg-5 mx-auto my-5">
          <h1 class="display-3 fw-bold">Dokumentation</h1>
          <h3 class="fw-normal text-muted mb-3">Message Caller</h3>
          <!-- <div class="d-flex gap-3 justify-content-center lead fw-normal">
            <a class="icon-link" href="#">
              Learn more
              <i class="bi"><use xlink:href="#chevron-right"></use></i>
            </a>
            <a class="icon-link" href="#">
              Buy
              <i class="bi"><use xlink:href="#chevron-right"></use></i>
            </a>
          </div> -->
        </div>
        <div class="product-device shadow-sm d-none d-md-block"></div>
        <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </div>



  <div class="container px-4 py-5" id="icon-grid">
    <h2 class="pb-2 border-bottom">Icon grid</h2>

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
      <div class="col d-flex align-items-start">
        <i class="bi bi-bootstrap text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-cpu-fill text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>button
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-calendar3 text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-home text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-speedometer2 text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-toggles2 text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-geo-fill text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <i class="bi bi-tools text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"></i>
        <div>
          <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
    </div>
  </div>



  <div class="container px-4 py-5" id="hanging-icons">
    <h2 class="pb-2 border-bottom">Hanging icons</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <i class="bi bi-toggles2" width="1em" height="1em"><use xlink:href="#toggles2"></use></i>
        </div>
        <div>
          <h3 class="fs-2 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
          <a href="#" class="btn btn-primary">
            <i class="bi bi-heart-fill" width="32" height="32" fill="currentColor">
              <use xlink:href="bootstrap-icons.i#heart-fill"/>
            </i>
            Primary button
          </a>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <i class="bi bi-cpu-fill" width="1em" height="1em"><use xlink:href="#cpu-fill"></use></i>
        </div>
        <div>
          <h3 class="fs-2 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
          <a href="#" class="btn btn-primary">
            <i class="bi bi-wifi-off"></i>
            Primary button
          </a>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <i class="bi bi-tools" width="1em" height="1em"></i>
        </div>
        <div>
          <h3 class="fs-2 text-body-emphasis">Featured title</h3>
          <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
          <a href="#" class="btn btn-primary">
            Primary button
          </a>
        </div>
      </div>
    </div>
  </div>

    </main>


</template>