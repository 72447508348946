import {createRouter, createWebHistory} from 'vue-router';

import Login from './Pages/Login.vue';
import Register from './Pages/Register.vue';
import NotFound from './Pages/NotFound.vue';
import Dashboard from './Pages/admin/Dashboard.vue';
import Customers from './Pages/admin/Customers.vue';
import PhoneHistory from './Pages/admin/PhoneHistory.vue';
import Profile from './Pages/profile/Profile.vue';
import Abo from './Pages/abo/Abo.vue';
import AdminAbo from './Pages/admin/AdminAbo.vue';
import Shop from './Pages/shop/Shop.vue';
import Checkout from './Pages/shop/Checkout.vue';
import SelectAbo from './Pages/shop/SelectAbo.vue';
import DataProtection from './Pages/DataProtection.vue';
import Documentation from './Pages/Documentation.vue';
import Impressum from './Pages/Impressum.vue';
import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/shop' },
        { path: '/login', component:Login, meta: { requiresUnauth: true}  },
        { path: '/register', component:Register, meta: { requiresUnauth: true} },
        { path: '/profile', component:Profile, meta: { requiresAuth: true}  },
        { path: '/abos', component:Abo, meta: { requiresAuth: true}  },
        { path: '/adminabos', component:AdminAbo, meta: { requiresAuth: true,requiresAdmin : true}  },
        { path: '/admindashboard', component:Dashboard , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/customers', component:Customers , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/phonehistory', component:PhoneHistory , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/shop', component:Shop },
        // { path: '/shop/checkout', component:Checkout },
        { path: '/shop/checkout', component:Checkout , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/shop/selectabo', component:SelectAbo , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/dataprotection', component:DataProtection },
        { path: '/documentation', component:Documentation },
        { path: '/impressum', component:Impressum },
        { path: '/:notFound(.*)', component:NotFound }
    ]
});

router.beforeEach(function(to,_,next) {
    console.log('isAuth: ' + store.getters.isAuthenticated);
    console.log('isAdmin: ' + store.getters.isAdmin);

    if(to.meta.requiresAuth && !store.getters.isAuthenticated) {
        if(to.meta.requiresAdmin && !store.getters.isAdmin) {
            next('/dataprotection'); 
        } else {
            next('/login');
        }
        
    } else {
        next();
    }

    console.log('hide: false');
    localStorage.setItem('hide',false);
});

export default router;