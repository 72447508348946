<template>
    <main>
      <router-link to="/shop" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page">zum Shop</router-link>

    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">1 Jahr</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€31,99<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Allinclusive 1 Jahre</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <button type="button" @click="selectAbo(1)" class="w-100 btn btn-lg btn-primary">Kaufen</button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">3 Jahre</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€85,99<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Allinclusive 3 Jahre</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <button type="button" @click="selectAbo(3)" class="w-100 btn btn-lg btn-primary">Kaufen</button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm border-primary">
          <div class="card-header py-3 text-bg-primary border-primary">
            <h4 class="my-0 fw-normal">5 Jahre</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€139,99<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Allinclusive 5 Jahre</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <button type="button" @click="selectAbo(4)" class="w-100 btn btn-lg btn-primary">Kaufen</button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">Kostenlos</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€0<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>14 Tage testen</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <button type="button" @click="selectAbo(99)" class="w-100 btn btn-lg btn-outline-primary">Jetzt Testen</button>
          </div>
        </div>
      </div>
    </div>
    
<!-- 
    <h2 class="display-6 text-center mb-4">Compare plans</h2>

    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th style="width: 34%;"></th>
            <th style="width: 22%;">Free</th>
            <th style="width: 22%;">Pro</th>
            <th style="width: 22%;">Enterprise</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" class="text-start">Public</th>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Private</th>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <th scope="row" class="text-start">Permissions</th>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Sharing</th>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Unlimited members</th>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Extra security</th>
            <td></td>
            <td></td>
            <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
          </tr>
        </tbody>
      </table>
    </div> -->


  </main>
</template>


<script>
export default {
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  methods: {
    // async loadHistlist(refresh = false) {
    //   this.isLoading = true;
    //   try {
    //     await this.$store.dispatch('getphonehistory', {
    //       forceRefresh: refresh,
    //       token: this.$store.state.auth.sessiontoken,
    //       userid: this.$store.state.auth.userId,
    //     });

    //   } catch (error) {
    //     this.error = error.message || 'Something went wrong!';
    //   }
    //   this.isLoading = false;
    // },
    handleError() {
      this.error = null;
    },
    selectAbo(mode) {
      if (mode == 99) {
        console.log('mode: ' + mode);
        localStorage.setItem('checkoutoption',mode);
        this.$router.replace('/shop/checkout');
      }
      else if (mode == 1) {
        console.log('mode: ' + mode);
        localStorage.setItem('checkoutoption',mode);
        this.$router.replace('/shop/checkout');
      }
      else if (mode == 3) {
        console.log('mode: ' + mode);
        localStorage.setItem('checkoutoption',mode);
        this.$router.replace('/shop/checkout');
      }
      else if (mode == 4) {
        console.log('mode: ' + mode);
        localStorage.setItem('checkoutoption',mode);
        this.$router.replace('/shop/checkout');
      } else {
        console.log('mode: ' + mode);
      }
    },
  },
};
</script>

<style>
.col {
  margin-bottom: 2rem;
}
</style>